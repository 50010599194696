@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    height: 100vh;
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .content {
        max-width: 1000px;
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .content {
        max-width: 600px;
        overflow: hidden;
    }
    .bannerImg {
        width: 500px !important;
        min-width: 0px !important;
    }
}
